import { boolean, object, string } from 'yup';

const ValidationSchema = object().shape({
  names: string()
    .min(2, 'Longitud inválida')
    //.matches(/\w+/g, 'Ingrese nombre válido')
    
    .required('Campo requerido'),
  lastNames: string()
    .min(2, 'Longitud inválida')
    .required('Campo requerido'),
  email: string()
    .email('Ingrese un correo válido')
    .required('Campo requerido'),
  subject: string().required('Campo requerido'),
  acceptedTerms:boolean()
  .oneOf([true], 'Debes aceptar los términos y condiciones')
  .required('Campo requerido'),
});

export default ValidationSchema;
