import React from 'react';
import styled from 'styled-components';
import success from '../img/icon_ok_blue.svg';
import icontel from '../img/icon_tel.svg';
import iconwat from '../img/icon_wa.svg';
import { breakpointLarge } from '../styles/breakpoints';


const Title = styled.h3`
  color: black;
  margin: 0.625rem 0;
  font-size:1.5rem;
  font-weight:800;
  @media (min-width: ${breakpointLarge}) {
    font-size:2rem;
    text-align:start;
  }
`;
const Description = styled.div`
  color: black;
  font-size: 1rem;
  text-align:center;
  max-width: 340px;
  font-weight:300;
  @media (min-width: ${breakpointLarge}) {
    font-size: 1.1rem;
    text-align:start;
  }
`;

const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    margin:2rem;
    @media (min-width: ${breakpointLarge}) {
      align-items:start;
      justify-content:start; 
    }
    
`;

const Channels = styled.div`
    margin-top: 20px;
    h6{
        margin:0.4rem;
        font-size:1rem;
        font-weight:500;
        @media (min-width: ${breakpointLarge}) {
            display: none; 
        }
        
    }
    
    > p {
        margin: 10px 0px;
        > a {
            margin-left: 4px;
            text-decoration: none;
            font-weight: bold;
            color: ${(props) => props.theme.blueDark};
            &,
            &:link,
            &:visited {
                color: ${(props) => props.theme.blueDark};
            }
        }
    }
`;
const ContainerLines = styled.div`
    display:flex;
    gap:0.5rem;
    flex-direction:row;
    a{
        text-decoration: none;
        color: white;
    }
    @media (min-width: ${breakpointLarge}) {
        flex-direction:column;
        
    }

`
const LineContact = styled.div`
    font-weight:700;
    padding:  0 1.2rem 0 0.5rem;
    display:flex;
    font-size:1rem;
    color: white;
    border-radius: 1.5rem;
   
    background-color:${(props) => props.theme.blue};
    p{
        margin:0.7rem 0;
    }
    @media (max-width: ${breakpointLarge}) {
        span {
          display: none; 
        }
      }
   
`
 const EmailContact= styled.div`
  font-size:1rem;
  display:flex;
  background-color:${(props) => props.theme.gray200};
  margin-top:0.5rem;
  border-radius:1.5rem;
  padding:0.4rem 1.5rem 0.4rem 0.4rem;
  a{
    text-decoration: none;
    color: black;
    font-weight:600;
    }
    p{
        margin:0;
    }

 `
 
 const Success = styled.img`
    width:20px;
    margin:6px;
    @media (min-width: ${breakpointLarge}) {
        width:25px;
    }
`
const IconContact = styled.img`
    width:25px;
    margin:6px;
`
const Text =styled.div`
display:flex;
`
const Information = () => {
  return (
    <Container>
        <Title>Canales de Atención</Title>
        <Description>También puedes contactarnos por cualquiera de nuestros canales de atención:</Description>
        <Channels>
            <h6>Línea gratuita nacional: </h6>
            <ContainerLines>
                <Text>
                    <LineContact>
                        <IconContact src={icontel} alt="Icono del Telefono" />  
                        <p><span>Línea gratuita nacional: </span>018000413811</p>
                    </LineContact>
                </Text>
                <Text>
                <a href='https://wa.me/5744807833' target='_blanck'> 
                    <LineContact>
                        <IconContact src={iconwat } alt="Icono del whatsapp" /> 
                        <p>Whatsapp</p> 
                    </LineContact>
                </a>
                </Text>
            </ContainerLines>
            
            {/*<p>Haz clic <a href="!#" onClick={(event)=>{
                event.preventDefault();
                document.querySelector(".ripple-area").click()
            }}>aquí</a> para comunicarte con uno de nuestros agentes.</p>
        */}
            <Text>
                <EmailContact>
                    <Success src={success} alt="Icono de check ok" /> 
                <div>
                    <p>Correo electrónico: </p>
                    <a
                        href="mailto:ayuda@juanchotepresta.com"
                        title="ayuda@juanchotepresta.com"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                    ayuda@juanchotepresta.com
                    </a>
                </div>
                </EmailContact>
            </Text>
            <EmailContact>
                <Success src={success} alt="Icono de check ok" />   
                <div>
                    <p>Correo de notificaciones judiciales: </p>
                    <a
                        href="mailto:notificaciones@juanchotepresta.co"
                        title="notificaciones@juanchotepresta.co"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                    notificaciones@juanchotepresta.co
                    </a>
                </div>
            </EmailContact> 
        </Channels>
    </Container>
  )
}

export default Information