import styled from 'styled-components';

const ButtonGeneral = styled.a`
  font-weight: 800;
  text-decoration: none;
  padding: 0.6rem 1.75rem;
  color: white;
  background-color: ${p => p.theme.blue};
  border-radius: 3.5rem;
  position: relative;
  transition: all 0.25s ease;
  &:hover {
    cursor:pointer;
    background-color: ${p => p.theme.blueDark};
  }
 
`;

export default ButtonGeneral;
