import Fingerprint2 from 'fingerprintjs2';

export const fingerprint = new (class {
  hash = 'unknown';
  webgl = 'unknown';
  canvas = 'unknown';

  async compute() {
    await this.idleBrowser();

    const components = (await Fingerprint2.getPromise({
      excludes: { fonts: true, adBlock: true, audio: true, enumerateDevices: true },
    })).reduce(
      (obj, { key, value }) => {
        obj.hash = obj.hash + [value].join('');

        if (key in obj) {
          obj[key] = [value].join('');
        }

        return obj;
      },
      { webgl: '', canvas: '', hash: '' }
    );

    this.hash = Fingerprint2.x64hash128(components.hash, 31);
    this.webgl = Fingerprint2.x64hash128(components.webgl, 31);
    this.canvas = Fingerprint2.x64hash128(components.canvas, 31);
  }

  idleBrowser() {
    return new Promise(resolve => {
      /*if ('requestIdleCallback' in window) {
        window.requestIdleCallback(resolve);
      } else {*/
      setTimeout(resolve, 500);
      //}
    });
  }
})();
